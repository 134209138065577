@use 'assets/styles/utils/helper' as h;

.today {
  .ad--front {
    div[data-mps-slot^='boxrail'],
    div[data-mps-slot^='boxinline'],
    div[data-mps-slot^='boxflex'] {
      text-align: center;

      @include h.breakpoint-m {
        transform: none;
        width: auto;
        margin: 0;
        overflow-x: auto;
        -ms-overflow-x: hidden;
        -ms-overflow-y: hidden;
      }

      & > div[style*='width:300'],
      & > div[style*='width: 300'] {
        background-color: var(--white);
        // Override ad iframe wrapper div inline dimensions
        width: auto !important;
        height: auto !important;

        iframe {
          @include h.responsive-variations(margin, (s: h.rem(8px 0), m: h.rem(20px 15px), l: h.rem(30px)));
        }
      }

      & > div:not([style*='width']) {
        background-color: var(--white);

        iframe:not([width='360']) {
          @include h.responsive-variations(margin, (s: h.rem(8px 0), m: h.rem(20px 15px), l: h.rem(30px)));
        }
      }
    }

    div[data-mps-slot^='boxflex'] {
      @include h.breakpoint-l {
        padding-top: h.rem(30px);
      }
    }
  }
}

.today .ad.ad--sticky.ad--front {
  // Today cover has additional padding around ads
  top: h.rem(112px);
}
